/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MPIStrictHttpResponse } from '../../mpi-strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ConsumerPersonIdPhotoGet$Params {
  personId: string;
}

export function consumerPersonIdPhotoGet(http: HttpClient, rootUrl: string, params: ConsumerPersonIdPhotoGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, consumerPersonIdPhotoGet.PATH, 'get');
  if (params) {
    rb.path('personId', params.personId, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as MPIStrictHttpResponse<void>;
    })
  );
}

consumerPersonIdPhotoGet.PATH = '/Consumer/{personId}/Photo';
