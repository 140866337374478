/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrganizationStrictHttpResponse } from '../../organization-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrganizationAffiliationModel } from '../../models/organization-affiliation-model';

export interface AdminFhirIdAffiliationPost$Params {
  fhirId: string;
      body?: OrganizationAffiliationModel
}

export function adminFhirIdAffiliationPost(http: HttpClient, rootUrl: string, params: AdminFhirIdAffiliationPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, adminFhirIdAffiliationPost.PATH, 'post');
  if (params) {
    rb.path('fhirId', params.fhirId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as OrganizationStrictHttpResponse<string>;
    })
  );
}

adminFhirIdAffiliationPost.PATH = '/Admin/{fhirId}/Affiliation';
