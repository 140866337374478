/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrganizationBaseService } from '../organization-base-service';
import { OrganizationApiConfiguration } from '../organization-api-configuration';
import { OrganizationStrictHttpResponse } from '../organization-strict-http-response';

import { adminFhirIdAffiliationAffiliationFhirIdGet } from '../fn/admin/admin-fhir-id-affiliation-affiliation-fhir-id-get';
import { AdminFhirIdAffiliationAffiliationFhirIdGet$Params } from '../fn/admin/admin-fhir-id-affiliation-affiliation-fhir-id-get';
import { adminFhirIdAffiliationGet } from '../fn/admin/admin-fhir-id-affiliation-get';
import { AdminFhirIdAffiliationGet$Params } from '../fn/admin/admin-fhir-id-affiliation-get';
import { adminFhirIdAffiliationPost } from '../fn/admin/admin-fhir-id-affiliation-post';
import { AdminFhirIdAffiliationPost$Params } from '../fn/admin/admin-fhir-id-affiliation-post';
import { adminFhirIdBackgroundImageFileIdGet } from '../fn/admin/admin-fhir-id-background-image-file-id-get';
import { AdminFhirIdBackgroundImageFileIdGet$Params } from '../fn/admin/admin-fhir-id-background-image-file-id-get';
import { adminFhirIdBackgroundImageIdDelete } from '../fn/admin/admin-fhir-id-background-image-id-delete';
import { AdminFhirIdBackgroundImageIdDelete$Params } from '../fn/admin/admin-fhir-id-background-image-id-delete';
import { adminFhirIdBackgroundImageIdGet } from '../fn/admin/admin-fhir-id-background-image-id-get';
import { AdminFhirIdBackgroundImageIdGet$Params } from '../fn/admin/admin-fhir-id-background-image-id-get';
import { adminFhirIdBackgroundImagePost } from '../fn/admin/admin-fhir-id-background-image-post';
import { AdminFhirIdBackgroundImagePost$Params } from '../fn/admin/admin-fhir-id-background-image-post';
import { adminFhirIdBackgroundImagesGet } from '../fn/admin/admin-fhir-id-background-images-get';
import { AdminFhirIdBackgroundImagesGet$Params } from '../fn/admin/admin-fhir-id-background-images-get';
import { adminFhirIdBackgroundImageThumbNailIdGet } from '../fn/admin/admin-fhir-id-background-image-thumb-nail-id-get';
import { AdminFhirIdBackgroundImageThumbNailIdGet$Params } from '../fn/admin/admin-fhir-id-background-image-thumb-nail-id-get';
import { adminFhirIdBranchesGet } from '../fn/admin/admin-fhir-id-branches-get';
import { AdminFhirIdBranchesGet$Params } from '../fn/admin/admin-fhir-id-branches-get';
import { adminFhirIdConfigurationGet } from '../fn/admin/admin-fhir-id-configuration-get';
import { AdminFhirIdConfigurationGet$Params } from '../fn/admin/admin-fhir-id-configuration-get';
import { adminFhirIdConfigurationPost } from '../fn/admin/admin-fhir-id-configuration-post';
import { AdminFhirIdConfigurationPost$Params } from '../fn/admin/admin-fhir-id-configuration-post';
import { adminFhirIdEmailConfigurationGet } from '../fn/admin/admin-fhir-id-email-configuration-get';
import { AdminFhirIdEmailConfigurationGet$Params } from '../fn/admin/admin-fhir-id-email-configuration-get';
import { adminFhirIdGet } from '../fn/admin/admin-fhir-id-get';
import { AdminFhirIdGet$Params } from '../fn/admin/admin-fhir-id-get';
import { adminFhirIdHealthcareServiceGet } from '../fn/admin/admin-fhir-id-healthcare-service-get';
import { AdminFhirIdHealthcareServiceGet$Params } from '../fn/admin/admin-fhir-id-healthcare-service-get';
import { adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet } from '../fn/admin/admin-fhir-id-healthcare-service-healthcare-service-fhir-id-get';
import { AdminFhirIdHealthcareServiceHealthcareServiceFhirIdGet$Params } from '../fn/admin/admin-fhir-id-healthcare-service-healthcare-service-fhir-id-get';
import { adminFhirIdHealthcareServicePost } from '../fn/admin/admin-fhir-id-healthcare-service-post';
import { AdminFhirIdHealthcareServicePost$Params } from '../fn/admin/admin-fhir-id-healthcare-service-post';
import { adminFhirIdKeyValueSpecialitiesLanguageRegionGet } from '../fn/admin/admin-fhir-id-key-value-specialities-language-region-get';
import { AdminFhirIdKeyValueSpecialitiesLanguageRegionGet$Params } from '../fn/admin/admin-fhir-id-key-value-specialities-language-region-get';
import { adminFhirIdLocationGet } from '../fn/admin/admin-fhir-id-location-get';
import { AdminFhirIdLocationGet$Params } from '../fn/admin/admin-fhir-id-location-get';
import { adminFhirIdLocationLocationFhirIdGet } from '../fn/admin/admin-fhir-id-location-location-fhir-id-get';
import { AdminFhirIdLocationLocationFhirIdGet$Params } from '../fn/admin/admin-fhir-id-location-location-fhir-id-get';
import { adminFhirIdLocationPost } from '../fn/admin/admin-fhir-id-location-post';
import { AdminFhirIdLocationPost$Params } from '../fn/admin/admin-fhir-id-location-post';
import { adminFhirIdLogoGet } from '../fn/admin/admin-fhir-id-logo-get';
import { AdminFhirIdLogoGet$Params } from '../fn/admin/admin-fhir-id-logo-get';
import { adminFhirIdLogoIdGet } from '../fn/admin/admin-fhir-id-logo-id-get';
import { AdminFhirIdLogoIdGet$Params } from '../fn/admin/admin-fhir-id-logo-id-get';
import { adminFhirIdLogoImageIdGet } from '../fn/admin/admin-fhir-id-logo-image-id-get';
import { AdminFhirIdLogoImageIdGet$Params } from '../fn/admin/admin-fhir-id-logo-image-id-get';
import { adminFhirIdLogoPost } from '../fn/admin/admin-fhir-id-logo-post';
import { AdminFhirIdLogoPost$Params } from '../fn/admin/admin-fhir-id-logo-post';
import { adminFhirIdLogoThumbNailIdGet } from '../fn/admin/admin-fhir-id-logo-thumb-nail-id-get';
import { AdminFhirIdLogoThumbNailIdGet$Params } from '../fn/admin/admin-fhir-id-logo-thumb-nail-id-get';
import { adminFhirIdParticipatingAffiliationsGet } from '../fn/admin/admin-fhir-id-participating-affiliations-get';
import { AdminFhirIdParticipatingAffiliationsGet$Params } from '../fn/admin/admin-fhir-id-participating-affiliations-get';
import { adminFhirIdSpecialitiesLanguageRegionGet } from '../fn/admin/admin-fhir-id-specialities-language-region-get';
import { AdminFhirIdSpecialitiesLanguageRegionGet$Params } from '../fn/admin/admin-fhir-id-specialities-language-region-get';
import { adminLanguagePost } from '../fn/admin/admin-language-post';
import { AdminLanguagePost$Params } from '../fn/admin/admin-language-post';
import { adminLinkUnlinkHealthcareServicesToSpecialityPost } from '../fn/admin/admin-link-unlink-healthcare-services-to-speciality-post';
import { AdminLinkUnlinkHealthcareServicesToSpecialityPost$Params } from '../fn/admin/admin-link-unlink-healthcare-services-to-speciality-post';
import { adminPost } from '../fn/admin/admin-post';
import { AdminPost$Params } from '../fn/admin/admin-post';
import { adminSearchOrganizationPost } from '../fn/admin/admin-search-organization-post';
import { AdminSearchOrganizationPost$Params } from '../fn/admin/admin-search-organization-post';
import { adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost } from '../fn/admin/admin-speciality-linkded-unlinked-organization-health-care-services-post';
import { AdminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost$Params } from '../fn/admin/admin-speciality-linkded-unlinked-organization-health-care-services-post';
import { adminSpecialityPost } from '../fn/admin/admin-speciality-post';
import { AdminSpecialityPost$Params } from '../fn/admin/admin-speciality-post';
import { ConfigurationModel } from '../models/configuration-model';
import { HealthcareServiceLocationModel } from '../models/healthcare-service-location-model';
import { HealthCareServiceLocationSearchResultModel } from '../models/health-care-service-location-search-result-model';
import { HealthcareServiceModel } from '../models/healthcare-service-model';
import { HealthcareServiceSearchResultModel } from '../models/healthcare-service-search-result-model';
import { HealthCareServiceSpecialityLinkedModel } from '../models/health-care-service-speciality-linked-model';
import { KeyValueCodeModel } from '../models/key-value-code-model';
import { OrganizationAffiliationModel } from '../models/organization-affiliation-model';
import { OrganizationAffiliationSearchResultModel } from '../models/organization-affiliation-search-result-model';
import { OrganizationBackgroundImageModel } from '../models/organization-background-image-model';
import { OrganizationBranchModel } from '../models/organization-branch-model';
import { OrganizationEmailConfigurationModel } from '../models/organization-email-configuration-model';
import { OrganizationLogoListModel } from '../models/organization-logo-list-model';
import { OrganizationLogoModel } from '../models/organization-logo-model';
import { OrganizationModel } from '../models/organization-model';
import { OrganizationSearchResultModel } from '../models/organization-search-result-model';
import { SpecialityModel } from '../models/speciality-model';

@Injectable({ providedIn: 'root' })
export class OrganizationAdminService extends OrganizationBaseService {
  constructor(config: OrganizationApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminSearchOrganizationPost()` */
  static readonly AdminSearchOrganizationPostPath = '/Admin/SearchOrganization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchOrganizationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchOrganizationPost$Response(params?: AdminSearchOrganizationPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>> {
    return adminSearchOrganizationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSearchOrganizationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchOrganizationPost(params?: AdminSearchOrganizationPost$Params, context?: HttpContext): Observable<Array<OrganizationSearchResultModel>> {
    return this.adminSearchOrganizationPost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationSearchResultModel>>): Array<OrganizationSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `adminFhirIdGet()` */
  static readonly AdminFhirIdGetPath = '/Admin/{fhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdGet$Response(params: AdminFhirIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationModel>> {
    return adminFhirIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdGet(params: AdminFhirIdGet$Params, context?: HttpContext): Observable<OrganizationModel> {
    return this.adminFhirIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationModel>): OrganizationModel => r.body)
    );
  }

  /** Path part for operation `adminPost()` */
  static readonly AdminPostPath = '/Admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Response(params?: AdminPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
    return adminPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost(params?: AdminPost$Params, context?: HttpContext): Observable<string> {
    return this.adminPost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminFhirIdLocationGet()` */
  static readonly AdminFhirIdLocationGetPath = '/Admin/{fhirId}/Location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLocationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLocationGet$Response(params: AdminFhirIdLocationGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<HealthCareServiceLocationSearchResultModel>>> {
    return adminFhirIdLocationGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLocationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLocationGet(params: AdminFhirIdLocationGet$Params, context?: HttpContext): Observable<Array<HealthCareServiceLocationSearchResultModel>> {
    return this.adminFhirIdLocationGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<HealthCareServiceLocationSearchResultModel>>): Array<HealthCareServiceLocationSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `adminFhirIdLocationPost()` */
  static readonly AdminFhirIdLocationPostPath = '/Admin/{fhirId}/Location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLocationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdLocationPost$Response(params: AdminFhirIdLocationPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
    return adminFhirIdLocationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLocationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdLocationPost(params: AdminFhirIdLocationPost$Params, context?: HttpContext): Observable<string> {
    return this.adminFhirIdLocationPost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminFhirIdLocationLocationFhirIdGet()` */
  static readonly AdminFhirIdLocationLocationFhirIdGetPath = '/Admin/{fhirId}/Location/{locationFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLocationLocationFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLocationLocationFhirIdGet$Response(params: AdminFhirIdLocationLocationFhirIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<HealthcareServiceLocationModel>> {
    return adminFhirIdLocationLocationFhirIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLocationLocationFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLocationLocationFhirIdGet(params: AdminFhirIdLocationLocationFhirIdGet$Params, context?: HttpContext): Observable<HealthcareServiceLocationModel> {
    return this.adminFhirIdLocationLocationFhirIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<HealthcareServiceLocationModel>): HealthcareServiceLocationModel => r.body)
    );
  }

  /** Path part for operation `adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost()` */
  static readonly AdminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPostPath = '/Admin/SpecialityLinkdedUnlinkedOrganizationHealthCareServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost$Response(params?: AdminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<HealthCareServiceSpecialityLinkedModel>>> {
    return adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost(params?: AdminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost$Params, context?: HttpContext): Observable<Array<HealthCareServiceSpecialityLinkedModel>> {
    return this.adminSpecialityLinkdedUnlinkedOrganizationHealthCareServicesPost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<HealthCareServiceSpecialityLinkedModel>>): Array<HealthCareServiceSpecialityLinkedModel> => r.body)
    );
  }

  /** Path part for operation `adminLinkUnlinkHealthcareServicesToSpecialityPost()` */
  static readonly AdminLinkUnlinkHealthcareServicesToSpecialityPostPath = '/Admin/LinkUnlinkHealthcareServicesToSpeciality';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminLinkUnlinkHealthcareServicesToSpecialityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminLinkUnlinkHealthcareServicesToSpecialityPost$Response(params?: AdminLinkUnlinkHealthcareServicesToSpecialityPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<void>> {
    return adminLinkUnlinkHealthcareServicesToSpecialityPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminLinkUnlinkHealthcareServicesToSpecialityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminLinkUnlinkHealthcareServicesToSpecialityPost(params?: AdminLinkUnlinkHealthcareServicesToSpecialityPost$Params, context?: HttpContext): Observable<void> {
    return this.adminLinkUnlinkHealthcareServicesToSpecialityPost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminFhirIdHealthcareServiceGet()` */
  static readonly AdminFhirIdHealthcareServiceGetPath = '/Admin/{fhirId}/HealthcareService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdHealthcareServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthcareServiceGet$Response(params: AdminFhirIdHealthcareServiceGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<HealthcareServiceSearchResultModel>>> {
    return adminFhirIdHealthcareServiceGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdHealthcareServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthcareServiceGet(params: AdminFhirIdHealthcareServiceGet$Params, context?: HttpContext): Observable<Array<HealthcareServiceSearchResultModel>> {
    return this.adminFhirIdHealthcareServiceGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<HealthcareServiceSearchResultModel>>): Array<HealthcareServiceSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `adminFhirIdHealthcareServicePost()` */
  static readonly AdminFhirIdHealthcareServicePostPath = '/Admin/{fhirId}/HealthcareService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdHealthcareServicePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdHealthcareServicePost$Response(params: AdminFhirIdHealthcareServicePost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
    return adminFhirIdHealthcareServicePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdHealthcareServicePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdHealthcareServicePost(params: AdminFhirIdHealthcareServicePost$Params, context?: HttpContext): Observable<string> {
    return this.adminFhirIdHealthcareServicePost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet()` */
  static readonly AdminFhirIdHealthcareServiceHealthcareServiceFhirIdGetPath = '/Admin/{fhirId}/HealthcareService/{healthcareServiceFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet$Response(params: AdminFhirIdHealthcareServiceHealthcareServiceFhirIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<HealthcareServiceModel>> {
    return adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet(params: AdminFhirIdHealthcareServiceHealthcareServiceFhirIdGet$Params, context?: HttpContext): Observable<HealthcareServiceModel> {
    return this.adminFhirIdHealthcareServiceHealthcareServiceFhirIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<HealthcareServiceModel>): HealthcareServiceModel => r.body)
    );
  }

  /** Path part for operation `adminFhirIdAffiliationGet()` */
  static readonly AdminFhirIdAffiliationGetPath = '/Admin/{fhirId}/Affiliation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdAffiliationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdAffiliationGet$Response(params: AdminFhirIdAffiliationGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<OrganizationAffiliationSearchResultModel>>> {
    return adminFhirIdAffiliationGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdAffiliationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdAffiliationGet(params: AdminFhirIdAffiliationGet$Params, context?: HttpContext): Observable<Array<OrganizationAffiliationSearchResultModel>> {
    return this.adminFhirIdAffiliationGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationAffiliationSearchResultModel>>): Array<OrganizationAffiliationSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `adminFhirIdAffiliationPost()` */
  static readonly AdminFhirIdAffiliationPostPath = '/Admin/{fhirId}/Affiliation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdAffiliationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdAffiliationPost$Response(params: AdminFhirIdAffiliationPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
    return adminFhirIdAffiliationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdAffiliationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdAffiliationPost(params: AdminFhirIdAffiliationPost$Params, context?: HttpContext): Observable<string> {
    return this.adminFhirIdAffiliationPost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminFhirIdParticipatingAffiliationsGet()` */
  static readonly AdminFhirIdParticipatingAffiliationsGetPath = '/Admin/{fhirId}/ParticipatingAffiliations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdParticipatingAffiliationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdParticipatingAffiliationsGet$Response(params: AdminFhirIdParticipatingAffiliationsGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<OrganizationAffiliationSearchResultModel>>> {
    return adminFhirIdParticipatingAffiliationsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdParticipatingAffiliationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdParticipatingAffiliationsGet(params: AdminFhirIdParticipatingAffiliationsGet$Params, context?: HttpContext): Observable<Array<OrganizationAffiliationSearchResultModel>> {
    return this.adminFhirIdParticipatingAffiliationsGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationAffiliationSearchResultModel>>): Array<OrganizationAffiliationSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `adminFhirIdAffiliationAffiliationFhirIdGet()` */
  static readonly AdminFhirIdAffiliationAffiliationFhirIdGetPath = '/Admin/{fhirId}/Affiliation/{affiliationFhirId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdAffiliationAffiliationFhirIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdAffiliationAffiliationFhirIdGet$Response(params: AdminFhirIdAffiliationAffiliationFhirIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationAffiliationModel>> {
    return adminFhirIdAffiliationAffiliationFhirIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdAffiliationAffiliationFhirIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdAffiliationAffiliationFhirIdGet(params: AdminFhirIdAffiliationAffiliationFhirIdGet$Params, context?: HttpContext): Observable<OrganizationAffiliationModel> {
    return this.adminFhirIdAffiliationAffiliationFhirIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationAffiliationModel>): OrganizationAffiliationModel => r.body)
    );
  }

  /** Path part for operation `adminFhirIdBranchesGet()` */
  static readonly AdminFhirIdBranchesGetPath = '/Admin/{fhirId}/Branches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdBranchesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBranchesGet$Response(params: AdminFhirIdBranchesGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<OrganizationBranchModel>>> {
    return adminFhirIdBranchesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdBranchesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBranchesGet(params: AdminFhirIdBranchesGet$Params, context?: HttpContext): Observable<Array<OrganizationBranchModel>> {
    return this.adminFhirIdBranchesGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationBranchModel>>): Array<OrganizationBranchModel> => r.body)
    );
  }

  /** Path part for operation `adminFhirIdLogoGet()` */
  static readonly AdminFhirIdLogoGetPath = '/Admin/{fhirId}/Logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLogoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoGet$Response(params: AdminFhirIdLogoGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<OrganizationLogoListModel>>> {
    return adminFhirIdLogoGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLogoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoGet(params: AdminFhirIdLogoGet$Params, context?: HttpContext): Observable<Array<OrganizationLogoListModel>> {
    return this.adminFhirIdLogoGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationLogoListModel>>): Array<OrganizationLogoListModel> => r.body)
    );
  }

  /** Path part for operation `adminFhirIdLogoPost()` */
  static readonly AdminFhirIdLogoPostPath = '/Admin/{fhirId}/Logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLogoPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdLogoPost$Response(params: AdminFhirIdLogoPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
    return adminFhirIdLogoPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLogoPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdLogoPost(params: AdminFhirIdLogoPost$Params, context?: HttpContext): Observable<string> {
    return this.adminFhirIdLogoPost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminFhirIdLogoIdGet()` */
  static readonly AdminFhirIdLogoIdGetPath = '/Admin/{fhirId}/Logo/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLogoIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoIdGet$Response(params: AdminFhirIdLogoIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationLogoModel>> {
    return adminFhirIdLogoIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLogoIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoIdGet(params: AdminFhirIdLogoIdGet$Params, context?: HttpContext): Observable<OrganizationLogoModel> {
    return this.adminFhirIdLogoIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationLogoModel>): OrganizationLogoModel => r.body)
    );
  }

  /** Path part for operation `adminFhirIdLogoImageIdGet()` */
  static readonly AdminFhirIdLogoImageIdGetPath = '/Admin/{fhirId}/LogoImage/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLogoImageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoImageIdGet$Response(params: AdminFhirIdLogoImageIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<void>> {
    return adminFhirIdLogoImageIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLogoImageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoImageIdGet(params: AdminFhirIdLogoImageIdGet$Params, context?: HttpContext): Observable<void> {
    return this.adminFhirIdLogoImageIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminFhirIdLogoThumbNailIdGet()` */
  static readonly AdminFhirIdLogoThumbNailIdGetPath = '/Admin/{fhirId}/LogoThumbNail/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdLogoThumbNailIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoThumbNailIdGet$Response(params: AdminFhirIdLogoThumbNailIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<void>> {
    return adminFhirIdLogoThumbNailIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdLogoThumbNailIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdLogoThumbNailIdGet(params: AdminFhirIdLogoThumbNailIdGet$Params, context?: HttpContext): Observable<void> {
    return this.adminFhirIdLogoThumbNailIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminFhirIdBackgroundImagePost()` */
  static readonly AdminFhirIdBackgroundImagePostPath = '/Admin/{fhirId}/BackgroundImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdBackgroundImagePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdBackgroundImagePost$Response(params: AdminFhirIdBackgroundImagePost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationBackgroundImageModel>> {
    return adminFhirIdBackgroundImagePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdBackgroundImagePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdBackgroundImagePost(params: AdminFhirIdBackgroundImagePost$Params, context?: HttpContext): Observable<OrganizationBackgroundImageModel> {
    return this.adminFhirIdBackgroundImagePost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationBackgroundImageModel>): OrganizationBackgroundImageModel => r.body)
    );
  }

  /** Path part for operation `adminFhirIdBackgroundImageIdGet()` */
  static readonly AdminFhirIdBackgroundImageIdGetPath = '/Admin/{fhirId}/BackgroundImage/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdBackgroundImageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBackgroundImageIdGet$Response(params: AdminFhirIdBackgroundImageIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationBackgroundImageModel>> {
    return adminFhirIdBackgroundImageIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdBackgroundImageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBackgroundImageIdGet(params: AdminFhirIdBackgroundImageIdGet$Params, context?: HttpContext): Observable<OrganizationBackgroundImageModel> {
    return this.adminFhirIdBackgroundImageIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationBackgroundImageModel>): OrganizationBackgroundImageModel => r.body)
    );
  }

  /** Path part for operation `adminFhirIdBackgroundImageIdDelete()` */
  static readonly AdminFhirIdBackgroundImageIdDeletePath = '/Admin/{fhirId}/BackgroundImage/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdBackgroundImageIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBackgroundImageIdDelete$Response(params: AdminFhirIdBackgroundImageIdDelete$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<void>> {
    return adminFhirIdBackgroundImageIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdBackgroundImageIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBackgroundImageIdDelete(params: AdminFhirIdBackgroundImageIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.adminFhirIdBackgroundImageIdDelete$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminFhirIdBackgroundImagesGet()` */
  static readonly AdminFhirIdBackgroundImagesGetPath = '/Admin/{fhirId}/BackgroundImages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdBackgroundImagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBackgroundImagesGet$Response(params: AdminFhirIdBackgroundImagesGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<OrganizationBackgroundImageModel>>> {
    return adminFhirIdBackgroundImagesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdBackgroundImagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBackgroundImagesGet(params: AdminFhirIdBackgroundImagesGet$Params, context?: HttpContext): Observable<Array<OrganizationBackgroundImageModel>> {
    return this.adminFhirIdBackgroundImagesGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationBackgroundImageModel>>): Array<OrganizationBackgroundImageModel> => r.body)
    );
  }

  /** Path part for operation `adminFhirIdBackgroundImageFileIdGet()` */
  static readonly AdminFhirIdBackgroundImageFileIdGetPath = '/Admin/{fhirId}/BackgroundImageFile/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdBackgroundImageFileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBackgroundImageFileIdGet$Response(params: AdminFhirIdBackgroundImageFileIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<void>> {
    return adminFhirIdBackgroundImageFileIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdBackgroundImageFileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBackgroundImageFileIdGet(params: AdminFhirIdBackgroundImageFileIdGet$Params, context?: HttpContext): Observable<void> {
    return this.adminFhirIdBackgroundImageFileIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminFhirIdBackgroundImageThumbNailIdGet()` */
  static readonly AdminFhirIdBackgroundImageThumbNailIdGetPath = '/Admin/{fhirId}/BackgroundImageThumbNail/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdBackgroundImageThumbNailIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBackgroundImageThumbNailIdGet$Response(params: AdminFhirIdBackgroundImageThumbNailIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<void>> {
    return adminFhirIdBackgroundImageThumbNailIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdBackgroundImageThumbNailIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdBackgroundImageThumbNailIdGet(params: AdminFhirIdBackgroundImageThumbNailIdGet$Params, context?: HttpContext): Observable<void> {
    return this.adminFhirIdBackgroundImageThumbNailIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminFhirIdSpecialitiesLanguageRegionGet()` */
  static readonly AdminFhirIdSpecialitiesLanguageRegionGetPath = '/Admin/{fhirId}/Specialities/{languageRegion}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdSpecialitiesLanguageRegionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdSpecialitiesLanguageRegionGet$Response(params: AdminFhirIdSpecialitiesLanguageRegionGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<SpecialityModel>>> {
    return adminFhirIdSpecialitiesLanguageRegionGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdSpecialitiesLanguageRegionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdSpecialitiesLanguageRegionGet(params: AdminFhirIdSpecialitiesLanguageRegionGet$Params, context?: HttpContext): Observable<Array<SpecialityModel>> {
    return this.adminFhirIdSpecialitiesLanguageRegionGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<SpecialityModel>>): Array<SpecialityModel> => r.body)
    );
  }

  /** Path part for operation `adminFhirIdKeyValueSpecialitiesLanguageRegionGet()` */
  static readonly AdminFhirIdKeyValueSpecialitiesLanguageRegionGetPath = '/Admin/{fhirId}/KeyValueSpecialities/{languageRegion}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdKeyValueSpecialitiesLanguageRegionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdKeyValueSpecialitiesLanguageRegionGet$Response(params: AdminFhirIdKeyValueSpecialitiesLanguageRegionGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<KeyValueCodeModel>>> {
    return adminFhirIdKeyValueSpecialitiesLanguageRegionGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdKeyValueSpecialitiesLanguageRegionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdKeyValueSpecialitiesLanguageRegionGet(params: AdminFhirIdKeyValueSpecialitiesLanguageRegionGet$Params, context?: HttpContext): Observable<Array<KeyValueCodeModel>> {
    return this.adminFhirIdKeyValueSpecialitiesLanguageRegionGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<KeyValueCodeModel>>): Array<KeyValueCodeModel> => r.body)
    );
  }

  /** Path part for operation `adminSpecialityPost()` */
  static readonly AdminSpecialityPostPath = '/Admin/Speciality';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSpecialityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSpecialityPost$Response(params?: AdminSpecialityPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
    return adminSpecialityPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSpecialityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSpecialityPost(params?: AdminSpecialityPost$Params, context?: HttpContext): Observable<string> {
    return this.adminSpecialityPost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminFhirIdConfigurationGet()` */
  static readonly AdminFhirIdConfigurationGetPath = '/Admin/{fhirId}/Configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdConfigurationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdConfigurationGet$Response(params: AdminFhirIdConfigurationGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<ConfigurationModel>> {
    return adminFhirIdConfigurationGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdConfigurationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdConfigurationGet(params: AdminFhirIdConfigurationGet$Params, context?: HttpContext): Observable<ConfigurationModel> {
    return this.adminFhirIdConfigurationGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<ConfigurationModel>): ConfigurationModel => r.body)
    );
  }

  /** Path part for operation `adminFhirIdConfigurationPost()` */
  static readonly AdminFhirIdConfigurationPostPath = '/Admin/{fhirId}/Configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdConfigurationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdConfigurationPost$Response(params: AdminFhirIdConfigurationPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<void>> {
    return adminFhirIdConfigurationPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdConfigurationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminFhirIdConfigurationPost(params: AdminFhirIdConfigurationPost$Params, context?: HttpContext): Observable<void> {
    return this.adminFhirIdConfigurationPost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminLanguagePost()` */
  static readonly AdminLanguagePostPath = '/Admin/Language';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminLanguagePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminLanguagePost$Response(params?: AdminLanguagePost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<string>> {
    return adminLanguagePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminLanguagePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminLanguagePost(params?: AdminLanguagePost$Params, context?: HttpContext): Observable<string> {
    return this.adminLanguagePost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminFhirIdEmailConfigurationGet()` */
  static readonly AdminFhirIdEmailConfigurationGetPath = '/Admin/{fhirId}/EmailConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFhirIdEmailConfigurationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdEmailConfigurationGet$Response(params: AdminFhirIdEmailConfigurationGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationEmailConfigurationModel>> {
    return adminFhirIdEmailConfigurationGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFhirIdEmailConfigurationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFhirIdEmailConfigurationGet(params: AdminFhirIdEmailConfigurationGet$Params, context?: HttpContext): Observable<OrganizationEmailConfigurationModel> {
    return this.adminFhirIdEmailConfigurationGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationEmailConfigurationModel>): OrganizationEmailConfigurationModel => r.body)
    );
  }

}
