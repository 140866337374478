import { WebPlugin, registerPlugin } from '@capacitor/core';

export interface DeviceCountryPlugin {
  getCurrentCountry(): Promise<{ country: string; device: string }>;
}

const DeviceCountry = registerPlugin<DeviceCountryPlugin>('DeviceCountryPlugin', {
  web: () => new DeviceCountryWeb(),
});
export default DeviceCountry;

export class DeviceCountryWeb extends WebPlugin implements DeviceCountryPlugin {
  async getCurrentCountry() {
    var tx = Intl.DateTimeFormat().resolvedOptions();
    var country = tx.timeZone;

    if (!country) {
      country = tx.locale;
      if (country && country.indexOf('-')) {
        country = country.split('-')[1];
      }
    }

    const params = new URLSearchParams(window.location.search);
    if (params && params.has('r')) {
      const r = params.get('r');
      if (r) {
        country = r;
      }
    }
    return {
      country: country,
      device: 'web',
    };
  }
}
